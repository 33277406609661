import React from 'react';
import { UseFormRegister, UseFormReturn } from 'react-hook-form';
import { AddressForm, AddressTypes, FormValidation } from '../../global-types/addresses';
import { RegionalSchema } from '../../regional-config/regional-config-types';
import { SubmittedAddresses } from '../../views/address/address-types';
import { Contact } from '../address-book';

export type AddressFormProps = {
  addressType: AddressTypes;
  addClass?: string;
  /** All the return values from the useForm hook */
  formHandlers: UseFormReturn<AddressForm>;
  shouldRenderEnvelope: boolean;
  mockConfig?: RegionalSchema;
  isLoadingAddresses: boolean;
  onSkipValidation: (addressesToValidate: FormValidation[]) => void;
  setSubmittedAddresses: React.Dispatch<React.SetStateAction<SubmittedAddresses>>;
  setShouldSave: (shouldSave: boolean) => void;
};

export type AddressBookButtonProps = {
  click: () => void;
};

export type CollapseSectionProps = {
  children: React.ReactNode;
};

export type EnvelopeRadioGroupProps = {
  /** The method from react-hook-form to register the radio cards to the form */
  register: UseFormRegister<AddressForm>;
};

export enum DeliveryModes {
  MailToRecipient = 'M',
  ShipToMe = 'S',
  None = '',
}

export type UsStateData = {
  code: string;
  desc: string;
  display_indicator: boolean;
};

export type UsStateOptions = {
  value: string;
  label: string;
};

export type AddressBookDialogProps = {
  handleAddressBookSubmit: (newAddress: Contact) => void;
  formTitle: string;
  isLoadingAddresses: boolean;
  isAddressBookOpen: boolean;
  onCloseAddressBook: () => void;
};
