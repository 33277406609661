import { CardFaceData, SpellcheckErrors, SpellcheckSuggestion } from '../../global-types';
import { getAllTextsWithErrors } from './get-all-texts-with-errors';
import { unhighlightText } from './unhighlight-text';

/**
 * Function that resets all text highlights
 *
 * @param cardFaces - Array of card faces
 * @returns void
 * @example resetAllTextsHighlight(cardState.cardFacesList)
 */
export const resetAllTextsHighlight = (cardFaces: CardFaceData[]) => {
  if (!cardFaces || cardFaces.length < 1) {
    return;
  }

  const textsWithErrors = getAllTextsWithErrors(cardFaces) as SpellcheckErrors;

  if (!textsWithErrors) {
    return;
  }

  const textObjects = Object.values(textsWithErrors).flat();

  textObjects.forEach((textObject) => {
    const text = textObject?.text;
    if (!text) {
      return;
    }

    const spellingSuggestions: SpellcheckSuggestion[] = textObject.data.suggestions || [];
    // Loop through the mispelled words and unhighlight them
    spellingSuggestions.forEach((suggestion) => {
      const start = text.indexOf(suggestion.word); // get start to misspelled word
      const end = start + suggestion.word.length; // get end to misspelled word
      unhighlightText(textObject, start, end);
    });

    const canvas = textObject.canvas;
    canvas?.requestRenderAll();
  });
};
