import { fabric } from 'fabric';
import { BrandColors } from '@hallmark/web.styles.colors';
import editTextIconUK from '../assets/edit-icon-uk.svg';
import editTextIcon from '../assets/edit-icon.svg';
import { CardContextState } from '../context/card-context';
import { FabricObject, CustomFabricObject, CardFaceData, EditableTextSettings, FabricTextBox } from '../global-types';
import colorsList from '../styles/util.scss';
import { findTextById } from './utility/find-text-by-id';

/**
 * This is added to custom objects in the data property
 * to make us easier identify these objects
 * @example object.data = { type: CanvasDataTypes.PhotoZone }
 */
export enum CanvasDataTypes {
  EditableText = 'editable-text',
  EditableTextButton = 'editable-text-button',
  EditableArea = 'editable-area',
  Placeholder = 'placeholder',
  UserText = 'user-text',
  PhotoZone = 'photo-zone',
  PhotoTextZone = 'photo-text-zone',
  PhotoTextZoneLabel = 'photo-text-zone-label',
  PhotoZoneImage = 'photo-zone-image',
  PhotoTextZoneButton = 'photo-text-zone-button',
  PhotoTextZoneTextButton = 'photo-text-zone-text-button',
  PhotoTextZoneResetButton = 'photo-text-zone-reset-button',
  PhotoTextZoneWAMButton = 'photo-text-zone-wam-button',
  PhotoZoneButton = 'photo-zone-button',
  PhotoZoneTextbox = 'photo-zone-textbox',
  UserImage = 'user-image',
  TemporaryTAMInput = 'temporary-tam-input',
  FoldLine = 'fold-line',
  RecipientBox = 'recipient-box',
  RecipientText = 'recipient-text',
  SenderBox = 'sender-box',
  SenderText = 'sender-text',
  UserZoneAddTextButton = 'user-zone-add-text-button',
  UserZoneAddWamButton = 'user-zone-add-wam-button',
  UserZoneInstructions = 'user-zone-add-instructions',
  PhotoIcon = 'photo-icon',
  RemovableOnSave = 'removable-on-save',
  PhotoFrameName = 'photo-frame',
}

/**
 * Checks in the fabric instance if we already loaded open type with the custom property hasLoadedOpenType
 */
export const hasFabricLoadedOpenType = () => !!(fabric as any).hasLoadedOpenType;

export const getObjectsByType = (canvas: fabric.Canvas, type: CanvasDataTypes) => {
  if (!canvas) {
    throw new Error('Canvas is undefined');
  }

  return canvas.getObjects().filter((obj) => {
    return obj.data?.type === type;
  });
};

export const getGroupedTextObject = (activeCanvas?: fabric.Canvas | null, zone: fabric.Group | null = null) => {
  if (zone) {
    const groupedTextbox = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, zone);
    return groupedTextbox as fabric.Textbox;
  } else if (!zone && activeCanvas?.getActiveObject()?.type === 'group') {
    const objectGroup = activeCanvas?.getActiveObject() as fabric.Group;
    const groupedTextbox = getGroupedItemByName(CanvasDataTypes.PhotoZoneTextbox, objectGroup);
    return groupedTextbox as fabric.Textbox;
  }

  return activeCanvas?.getActiveObject();
};

export const getGroupedItemByName = (name: CanvasDataTypes, currentGroup: fabric.Group) => {
  return currentGroup._objects.find((item) => item?.name?.includes(name));
};

export const getObjectByName = (name: string, currentCanvas: fabric.Canvas) => {
  return currentCanvas?.getObjects().find((object) => name !== undefined && object.name === name);
};

/**
 *
 * @param object fabric object to get the Y position on the card
 * @returns "top" if the provided object is on the top half of the card and "bottom" if it's on the bottom half
 */
export const getObjectPositionY = (object: fabric.Object) => {
  const canvas = object.canvas;
  if (!canvas) {
    return;
  }
  const canvasHalfHeight = canvas.getHeight() / 2;
  const { top = 0 } = object;
  return top < canvasHalfHeight ? 'top' : 'bottom';
};

/**
 *
 * @param object fabric object to check if it is a photo text zone
 * @returns true if the provided object is a photo text zone
 */
export const isPhotoTextZone = (object: fabric.Object): object is fabric.Group => {
  return !!object.name?.startsWith(CanvasDataTypes.PhotoTextZone);
};

/**
 *
 * @param object fabric object to check if it is a photo zone
 * @returns true if the provided object is a photo zone
 */
export const isPhotoZone = (object: fabric.Object): object is fabric.Object => {
  return object.data?.type === CanvasDataTypes.PhotoZone;
};

/**
 *
 * @param object fabric object to check if it is a editable text
 * @returns true if the provided object is a editable text
 */
export const isEditableText = (object: fabric.Object): object is fabric.Textbox => {
  return object.data?.type === CanvasDataTypes.EditableText;
};

/**
 *
 * @param object fabric object to check if it is a user zone. By user zone we understand any zone that can be edited by users.
 * For instance: Photo Text Zones, Photo Zones and Editable Texts
 * @returns true if the provided object is either a Photo Text Zone, a Photo Zone or an Editable Text
 */
export const isUserZone = (object: fabric.Object) => {
  return isPhotoTextZone(object) || isPhotoZone(object) || isEditableText(object);
};

/**
 *
 * @param photoTextZone The photo text zone from which you will get the description group containing the label and icon
 * @returns the fabric Group inside photo text zone containing icon and label
 */
export const getPhotoTextZoneDescription = (photoTextZone: fabric.Group): fabric.Group | undefined => {
  const groups = photoTextZone.getObjects('group') as fabric.Group[];
  return groups.find((obj) => obj.name === CanvasDataTypes.PhotoTextZoneButton);
};

export const mergeLines = (textbox: fabric.Textbox) => {
  const offset = textbox.selectionEnd || 0;
  textbox.text = textbox.text?.replace(/\n$/, '');
  if (textbox.hiddenTextarea && textbox.text) {
    textbox.hiddenTextarea.value = textbox.text;
  }
  textbox.setSelectionStart(offset - 1);
  textbox.setSelectionEnd(offset);
  if (textbox.canvas) {
    textbox.render(textbox.canvas.getContext());
  }
};

export const MIN_FONT_SIZE = 16;

export const shrinkText = (textbox: fabric.Textbox, minFontSize: number = MIN_FONT_SIZE) => {
  const currentFontSize = textbox.fontSize as number;
  const newFontSize = currentFontSize - 1 < minFontSize ? minFontSize : currentFontSize - 1;
  textbox.fontSize = newFontSize;
  if (textbox.canvas) {
    textbox.render(textbox.canvas.getContext());
  }
};

export const expandText = (textbox: fabric.Textbox, maxFontSize: number) => {
  const currentFontSize = textbox.fontSize as number;
  const newFontSize = currentFontSize + 1 > maxFontSize ? maxFontSize : currentFontSize + 1;
  textbox.fontSize = newFontSize;
  if (textbox.canvas) {
    textbox.render(textbox.canvas.getContext());
  }
};

export const spaceContentEvenly = (group: fabric.Group, gap: number, start = 0) => {
  const items = group._objects;
  items.forEach((item, index) => {
    const previousItem = items[index - 1];
    item.set('left', previousItem ? (previousItem.left as number) + previousItem.getScaledWidth() + gap : start);
  });
};

export const getMaxWidthLine = (textbox: fabric.Textbox) => {
  const linesWidth = textbox.textLines.map((_, index) => textbox.measureLine(index).width);
  return Math.max(...linesWidth);
};

/**
 * rotates an object around its center
 * @param targetObject object to rotate
 * @param rotation angle for rotation
 */
export const setAngle = (targetObject: FabricObject, rotation: number) => {
  (targetObject as CustomFabricObject)._setOriginToCenter();
  (targetObject as CustomFabricObject).set('angle', rotation).setCoords();
  (targetObject as CustomFabricObject)._resetOrigin();
};

/**
 * Calculates the offset between a zone description's label width and measured char width
 * @param zoneDescription zone description containing button and label
 * @returns {number} Returns a photoTextZone's label's offset
 */
export const getZoneLabelOffset = (zoneDescription: fabric.Group): number => {
  let offset = 0;
  const zoneLabel = zoneDescription
    .getObjects()
    .find((obj) => obj.name === CanvasDataTypes.PhotoTextZoneLabel) as fabric.Text;
  if (zoneLabel) {
    const measureDiff = zoneLabel.measureLine(0).width - (zoneLabel.width as number);
    offset = measureDiff > 0 ? measureDiff : 0;
  }
  return offset;
};

/**
 * Hides an object's middle controls
 * @param targetObject target object
 */
export const hideMiddleControls = (targetObject: fabric.Object) => {
  targetObject.setControlsVisibility({
    mt: false,
    mb: false,
    ml: false,
    mr: false,
  });
};

/**
 * add event handlers for customizable texts (placeholder or user-added)
 * @param textElement the text element to add handlers to
 * @param defaultMessage the default message to display on the textbox when it hasn't been edited
 * @param [defaultColor] optional default text color to apply to default message
 *
 * @example
 *
 *  addUserTextHandlers(textElement, INITIAL_TEXT_MESSAGE, BrandColors.purple)
 */
export const addUserTextHandlers = (
  textElement: fabric.Textbox,
  defaultMessage: string,
  defaultColor: string,
  currentCanvas: fabric.Canvas,
) => {
  textElement.on('editing:entered', function (this: fabric.Textbox & { _textBeforeEdit: string }) {
    if (this._textBeforeEdit === defaultMessage) {
      const currentFill = currentCanvas.getActiveObject()?.fill;
      const currentFillMatch = Object.keys(colorsList).find(
        (key) => colorsList[`${key}`].toUpperCase() === currentFill?.toString().toUpperCase(),
      );
      const colorName = currentFillMatch || BrandColors.Black;
      this.set({ text: '', fill: colorsList[`${colorName}`], data: { ...this.data, edited: true } });
      if (this.hiddenTextarea) {
        this.hiddenTextarea.value = '';
      }
    }
  });
  textElement.on('editing:exited', function (this: fabric.Textbox) {
    if (this.text === '') {
      this.set({
        text: defaultMessage,
        data: { ...this.data, isEdited: false, edited: false },
      });
      return;
    }
    this.set('data', { ...this.data, isEdited: true });
  });
  textElement.on(
    'mousedblclick',
    function (this: fabric.Textbox & { _textBeforeEdit: string; __lastIsEditing: boolean }) {
      if (this._textBeforeEdit !== defaultMessage || this.__lastIsEditing) {
        this.selectAll();
      }
    },
  );
};

/**
 * gets a clipPath if present on current canvas so that images and text can be added within
 * @param currentFace current cardface from cardFacesList, not the cardState.activeCanvas
 * @param areaIndex index of the activeArea in the array of editableAreas
 *
 * @example
 *
 *  getCardFaceClipPath(cardState.cardFacesList[cardState.activeCardIndex], 0);
 */
export const getCardFaceClipPath = (currentFace: any, areaIndex: number) => {
  if (!currentFace.clipPaths) return;
  return currentFace.clipPaths[`${areaIndex}`];
};

/**
 *
 * @param x will be image width
 * @param y will be image height
 * @param zw will be photozone width
 * @param zh will be photozone height
 * @returns scale ratio
 */
export const setPhotoZoneScaleRatio = (x: number, y: number, zw: number, zh: number) => {
  const ratio = Math.max(zh / y, zw / x);
  return ratio;
};

/**
 *   Determines if there are available photo zones in the current canvas.
 * @param canvas
 * @returns true if all the photo zones are filled.
 */
export function photoZonesAreFilled(canvas: fabric.Canvas) {
  const photoZones = getObjectsByType(canvas, CanvasDataTypes.PhotoZone);
  const slots = photoZones.filter((zone) => !zone.data?.hasContent);
  return slots.length === 0;
}
/** Determines if are photo zones awaitables in the canvas to be filled  */
export function hasAvailablePhotoZones(canvas: fabric.Canvas) {
  const photoZones = getObjectsByType(canvas, CanvasDataTypes.PhotoZone);
  const slots = photoZones.filter((zone) => !zone.data?.hasContent);
  return slots.length > 0;
}
/** Loads an edit icon for the editable texts
 */
export function getEditableTextButton(
  settings: EditableTextSettings,
  scale: number,
  isUK: boolean | undefined,
): Promise<fabric.Image> {
  return new Promise((resolve) => {
    // edit button
    fabric.Image.fromURL(
      isUK ? editTextIconUK : editTextIcon,
      (image) => {
        image.scaleToWidth(scale);

        image.setOptions({
          name: 'edit-icon',
          hoverCursor: 'pointer',
          originY: settings.originY,
          left: settings.left + settings.width / 2,
          top: settings.top,
          angle: settings.angle,
          data: {
            type: CanvasDataTypes.EditableTextButton,
          },
        });

        resolve(image);
      },
      { width: 48, height: 48 },
    );
  });
}

/**
 * Determines if the card has been edited even after reloading the page
 * @param faces
 * @returns true if the card has been edited since it has an originalCanvasJson property in any of the faces.
 */
export const getIsCardEditted = (faces: CardFaceData[]) => {
  return faces.some((face) => face.originalCanvasJson);
};

export function setEditableTextHandlers(
  image: fabric.Image,
  editableInput: FabricTextBox,
  maxFontSize: number,
  maxLines: number,
  cardState: CardContextState,
) {
  image.set({ selectable: false, evented: false });
  editableInput.onDeselect = () => {
    if (editableInput.text === '') {
      image.visible = true;
    }
    editableInput.exitEditing();
    return false;
  };

  editableInput.onSelect = () => {
    image.visible = false;
    editableInput.enterEditing();
    editableInput.selectAll();
    // Search for the template text field by ID to get the IsFixed value
    const templateTextField = findTextById(cardState.cardFacesList, editableInput.data.ID);

    editableInput.set({
      data: {
        ...editableInput.data,
        originalText: templateTextField?.Text,
        isEdited: true,
        hasContent: true,
        type: CanvasDataTypes.EditableText,
        maxFontSize,
        fixedWidth: editableInput.getScaledWidth(),
        maxLines,
      },
      lockMovementX: templateTextField ? templateTextField.IsFixed : true,
      lockMovementY: templateTextField ? templateTextField.IsFixed : true,
      CanResizeTextArea: templateTextField ? templateTextField.CanResizeTextArea : true,
      CanEditFontSize: templateTextField ? templateTextField.CanEditFontSize : true,
      FontAutoSize: templateTextField ? templateTextField.FontAutoSize : false,
      CanEditFontColor: templateTextField ? templateTextField.CanEditFontColor : false,
      MustEditTextArea: templateTextField ? templateTextField.MustEditTextArea : false,
    });
    return false;
  };
}
