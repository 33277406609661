import { EnvironmentsType } from '../global-types/feature-flags';
import { setEnabledEnvironments } from '../utils/utility/set-enabled-environments';

export const useFeatureFlags = () => {
  /**
   * Flags
   */

  // Should only show in US dev
  const DISPLAY_ZOOM_DEV = setEnabledEnvironments([EnvironmentsType.UsStage]); // Enable on dev for local development

  // Whether app should generate FE previews using canvasJson. Temporary to test previews, can remove after FE previews is tested and released
  // If CANVAS_JSON_PREVIEWS is false, FE will use printJson to generate previews
  const CANVAS_JSON_PREVIEW = setEnabledEnvironments([]);

  // feature for hiding and showing the toolbar based on if a S&S user zome is selected or not (MIMIR-3137)
  const SAS_TOOLBAR_TOGGLE = setEnabledEnvironments([]);

  // Enables or disables using the url from 'image_url' for the image added to the card in the thumbnail component (MIMIR-3180)
  const IS_URL_IMAGE_ENABLED = setEnabledEnvironments([
    EnvironmentsType.UsStage, // Change Flag to Dev when working locally
    EnvironmentsType.UkDev,
    EnvironmentsType.CeDev,
  ]);

  // TODO: Once this feature is enabled on UK, remove this flag and don't replace with a feature flag.
  // Enables FE Cropping
  const IS_FE_CROPPING_ENABLED = setEnabledEnvironments([
    EnvironmentsType.UsDev,
    EnvironmentsType.UsStage,
    EnvironmentsType.UsProd,
    EnvironmentsType.UkDev,
  ]);

  const IS_DRAWER_PAGINATION_ENABLED = setEnabledEnvironments([EnvironmentsType.UsDev]);

  // Enables the new styled dynamic buttons for S&S cards (added in MIMIR-3024)
  const SAS_DYNAMIC_BUTTONS = setEnabledEnvironments([EnvironmentsType.UsStage]);

  // Enables and disables components related to H Plus
  const IS_H_PLUS_ENABLED = setEnabledEnvironments([
    EnvironmentsType.UsDev,
    EnvironmentsType.UsStage,
    EnvironmentsType.UsProd,
  ]);

  // Enables and disables components related to loyalty (added in MIMIR-3260)
  const IS_LOYALTY_ENABLED = setEnabledEnvironments([
    EnvironmentsType.UsDev,
    EnvironmentsType.UsStage,
    EnvironmentsType.UsProd,
  ]);

  const IS_AUTOSAVE_PROJECTS_ENABLED = setEnabledEnvironments([EnvironmentsType.UsDev]);

  // Enables and disables spellcheck functionality (MIMIR-3259)
  const IS_SPELLCHECK_ENABLED = setEnabledEnvironments([
    EnvironmentsType.UsDev,
    EnvironmentsType.UsStage,
    EnvironmentsType.UsProd,
    EnvironmentsType.UkDev,
  ]);

  // Enables and disables the new Photo Drawer (MIMIR-3336)
  const IS_PHOTO_DRAWER_ENABLED = setEnabledEnvironments([EnvironmentsType.UsDev]);

  // Enables and disables address validation
  const IS_ADDRESS_VALIDATION_ENABLED = setEnabledEnvironments([
    EnvironmentsType.UsDev,
    EnvironmentsType.UsStage,
    EnvironmentsType.UsProd,
    EnvironmentsType.UkDev,
    EnvironmentsType.UkStage,
  ]);

  // Enables chatbot feature
  const IS_CHATBOT_ENABLED = setEnabledEnvironments([
    EnvironmentsType.UsDev,
    EnvironmentsType.UsStage,
    EnvironmentsType.UsProd,
  ]);

  const IS_ADDRESS_BULK_UPLOAD_ENABLED = setEnabledEnvironments([EnvironmentsType.UsDev]);

  return {
    IS_FE_CROPPING_ENABLED,
    DISPLAY_ZOOM_DEV,
    SAS_TOOLBAR_TOGGLE,
    IS_URL_IMAGE_ENABLED,
    SAS_DYNAMIC_BUTTONS,
    IS_AUTOSAVE_PROJECTS_ENABLED,
    IS_H_PLUS_ENABLED,
    CANVAS_JSON_PREVIEW,
    IS_LOYALTY_ENABLED,
    IS_SPELLCHECK_ENABLED,
    IS_CHATBOT_ENABLED,
    IS_ADDRESS_VALIDATION_ENABLED,
    IS_PHOTO_DRAWER_ENABLED,
    IS_DRAWER_PAGINATION_ENABLED,
    IS_ADDRESS_BULK_UPLOAD_ENABLED,
  };
};
